/* eslint-disable @next/next/no-img-element */
import React, {useState} from "react";
import { Parallax } from "react-parallax";
import Link from "next/link"

const Index = () => {
  const [isMuted, setIsMuted] = useState(true);

  const handleToggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    // <Parallax
    //   strength={200}
    //   bgImage="/img/backgrounds/background-2.jpg"
    //   bgImageAlt="amazing place"
    //   bgClassName="object-fit-cover"
    // >
    <section className="masthead -type-8">
      <div className="container-1500">
        <div className="masthead__image">
          <div className="row y-gap-30 flex-wrap">
            {/* <div className="col-lg-3 position-relative col-12">
              <img
                src="/img/masthead/10/president-macky-sall.png"
                alt="image"
                className="rounded-16"
              />
              <div className="image-overlay">
                <h1>President Macky Sall</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
            </div> */}

            <div className="col-lg-3 col-12 d-flex flex-column">
            <Link href="/memorial/lememorial/histoiredumemorial">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <img
                  src="/img/masthead/10/memorial-lejoola.png"
                  alt="image"
                  className="rounded-16 mb-3"
                />
                {/* <video
                    className="rounded-16" autoPlay loop muted style={{ width: '100%' }}>
                  <source src="/img/video/MBJOKOK.mp4" type="video/mp4" />
                </video> */}
                <div className="bateau-overlay">
                  <h2>Le Memorial !</h2>
                  {/* <p className="text-white">Ce navire qui assurait la liaison maritime Dakar-Ziguinchor sombre aux larges des côtes gambiennes.</p> */}
                </div>
              </div>
              </Link>

              <Link href="/memorial/presentation/lesenegal">
              <div
                className="position-relative"
                data-aos="fade-down"
                data-aos-delay="200"
              >
                <img
                  src="/img/masthead/10/president-Bassirou-Diomaye-Faye.png"
                  alt="image"
                  className="rounded-16"
                />
                <div className="president-overlay">
                  <h2>Président Bassirou Diomaye Faye</h2>
                  {/* <p className="text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                </div>
              </div>
              </Link>
            </div>

            {/* <div className="col-lg-6 col-12">
              <img
                src="/img/masthead/10/rush-middle.png"
                alt="image"
                className="rounded-16"
              />
            </div> */}

            {/* <div className="col-lg-6 col-12" style={{ position: 'relative' }}>
              <video autoPlay loop muted style={{ height: '90%', position: 'absolute', top: 20, left: 20, zIndex: -1 }}>
                <source src="/img/video/video-presentation-musee-memorial.mp4" type="video/mp4" />
              </video>
              <img
                src="/img/masthead/10/rush-middle2.png"
                alt="image"
                className="rounded-16"
                style={{ width: '110%' }}
              />
            </div> */}

            <div
              className="col-lg-6 col-12"
              data-aos="zoom-in-up"
              data-aos-delay="200"
            >
              <video
                className="rounded-16"
                autoPlay
                muted={isMuted} 
                loop
                style={{ width: "100%" }}
              >
                {/* <source src="/img/video/video-presentation-musee-memorial.mp4" type="video/mp4" /> */}
                <source
                  src="/img/video/video-memorial-le-joola-senegal.mp4"
                  type="video/mp4"
                />
                {/* Ajoutez des sources supplémentaires pour prendre en charge d'autres formats de vidéo si nécessaire */}
              </video>

              <button onClick={handleToggleMute}>
                {isMuted ? "Activer le son" : "Désactiver le son"}
              </button>
            </div>

            <div className="col-lg-3 col-12 d-flex flex-column">
            <Link href="/memorial/galerie">
            <div
                className="position-relative"
                data-aos="fade-down"
                data-aos-delay="200"
              >
                    <img
                      src="/img/masthead/10/mediateque-md2.png"
                      alt="image"
                      className="rounded-16 mb-3"
                    />
                    <div className="mediateque-overlay">
                      <h1>MÉDIATÈQUE</h1>
                    </div>
              </div>
              </Link>

              <Link href="/memorial/lememorial/visiteguidee">
              <div
                className="position-relative"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <img
                  src="/img/masthead/10/visite-guidee-md2.png"
                  alt="image"
                  className="rounded-16"
                />
                <div className="mediateque-overlay">
                  <h1>VISITE GUIDÉE</h1>
                </div>
              </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    // </Parallax>
  );
};

export default Index;
